import React from "react";
import HeaterList from "../components/heater-list"
import Layout from "../components/layout";
import SEO from "../components/seo";
import Hero from "../components/hero"
import { Link } from "gatsby"

export default function Electric({ data, pageContext }) {
  return (
    <Layout>
    
      <SEO
        title="Infrared Patio Heaters"
        keywords={[`infra-red patio heaters`, `infrared garden heaters`, `infrared outdoor headers`]}
        description="Infrared elextric patio heaters beam their warmth directly onto you - stay warm this winter"
      />

<Hero heading='Infrared Patio Heaters' subheading='Direct plug and play heat' />

<div className="flex flex-wrap w-full container px-3 mx-auto flex flex-wrap flex-col md:flex-row items-center">
<div className="p-6">
<h3 className="text-3xl text-gray-800 font-bold leading-none mb-3">The best infrared patio heaters.</h3>
<p className="text-gray-600">We've hand-picked the top infrared heaters on the market for you for explore.<br /><br /></p>
<HeaterList data= {data.products} />
</div>
</div>

    </Layout>
  );
}

export const query = graphql`
  query {
    products: allGoogleSheetProductsRow(filter: {powersource: {eq: "Electric"}}) {
      edges {
        node {
          productImage
          productName
        }
      }
    }
  }
`